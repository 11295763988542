
    // background-color: $color-bg;
    .home{
        height: auto;
        margin: 0;
        height: fit-content;
    }
    .mainboard{
        position: relative;
        z-index: 1;
        background-color:unset;
        height: auto;
        &__imge{
            position: absolute;
            background-image: url("/storage/app/media/main/top-bg.jpg");
            background-repeat: no-repeat;
            width: 100%;
            height: 100vh;
            background-repeat: no-repeat;
            background-size:cover;
            z-index: -1;
            top: -200px;
        }
        &__wraper{
            padding-top: 21px;
            display: flex;
            justify-content: space-between;
            @include mq($until: sm){
                flex-direction: column-reverse;
               align-items: center;
            }
        }
        &__slogan{
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            h1 {
                padding-top: 0;
                margin-top: 0;
                margin-bottom: 0;
                font-size: rem(65px);
                color: $color-heading ;
                max-width: 44vw;
                padding-left: 5vw;
                @include mq($until: xl){
                    font-size: rem(50px);
                    max-width: 34vw;
                }
                @include mq($until: md){
                    font-size: rem(40px);
                }
                @include mq($until: sm){
                   max-width: unset;
                    text-align: center;
                    padding-left: 0;
                }
                &:first-of-type {
                    padding-top: 5vw;
                    padding-left: 0;
                }
            }
            h4{
                font-size: rem(21px);
                font-weight: 100;
                font-family: 'Montserrat';
                max-width: 480px;
                color: $paragraph-color;
                @include mq($until: md){
                    font-size: rem(16px);
                }
                @include mq($until: sm){
                    font-size: rem(24px);
                    text-align: center;
                }
            }
            a {
                margin: 0 5vw;
                @include mq($until: sm){
                    text-align: center;
                }

            }
        }

        &__pic{
           width: 44vw;
           height: 36vw;
           @include mq($until: sm){
                width: 84vw;
                height: 66vw;
            }
        }
        &__buttpic{
            height: auto;
            @include mq($until: xl){
               max-width:15vw ;
            }
            @include mq($until: md){
               max-width:20vw ;
            }
            @include mq($until: sm){
               max-width:210px ;
            }
            transition: all .8s ease-in-out;
            &:hover{
                filter: hue-rotate(300deg);
            }
        }
        
        &__services {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            column-gap: 0;
            margin-bottom: -180px;
            margin-top: 20px;
            div:nth-child(2) {
                margin-top: -80px;
                .overlay:after {
                    background: rgba($color-extra, 0.88);
                }
            }
            div:nth-child(3) {
                margin-top: 0;
                .overlay:after {
                    background: rgba($color-hover, 0.88);
                }
                span {
                    bottom: 1.5vw;
                }
            }
            div:nth-child(4) {
                margin-top: 30px;
                .overlay:after {
                    background: rgba(64, 207, 140, 0.88);
                }
                span {
                    bottom: 2vw;
                }
            }
            div:nth-child(5) {
                margin-top: -60px;
                .overlay:after {
                    background: rgba(64, 169, 207, 0.88);
                }
            }
            a {
                position: relative;
                display: block;
                background-size: 99%;
                background-position: center;
                background-repeat: no-repeat;
                overflow: hidden;
                &:hover{
                    .overlay:after {
                        transform: translateY(-50%) translateX(-50%);
                        left: 50%;
                        top: 50%;
                    }
                    span {
                        transform: translateY(50%) translateX(-50%) scale(1.3);
                        left: 50%;
                        bottom: 50%!important;
                    }
                }
                svg {
                    width: 100%;
                    height: auto;
                    fill: #fff;
                    display: block;
                    z-index: 5;
                    position: relative;
                }
            }
            .overlay {
                position: absolute;
                border: 2px solid #fff;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
                overflow: hidden;
                &:after {
                    content: '';
                    background-color: rgba($color-main, 0.88);
                    width: 100%;
                    height: 100%;
                    top: 8vw;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 100%;
                    position: absolute;
                    transition: ease 0.3s all;
                }
            }
            span {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                //bottom: 40px;
                color: #fff;
                text-align: center;
                z-index: 3;
                width: 100%;
                transition: ease 0.3s all;
                //line-height: 24px;
                text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);

                font-size: 1vw;
                line-height: 1.3vw;
                bottom: 2.5vw;
            }
        }
        @include mq($until: lg){
            &__services {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;
                margin-top: 240px;
                row-gap: 30px;

                div {
                    width: 33%;
                }
                div:nth-child(2){
                    margin-top: -180px;
                }
                div:nth-child(3) span{
                    bottom: 2.3vw;
                }
                div:nth-child(4){
                    width: 50%;
                    margin: 0;
                    a {
                        width: 66%;
                        margin: 0 20px 0 auto;
                    }
                    span {
                        bottom: 3.3vw;
                    }
                }
                div:nth-child(5){
                    width: 50%;
                    margin: 0;
                    a {
                        width: 66%;
                        margin: 0 auto 0 20px;
                    }
                }
                span {
                    font-size: 1.8vw;
                    line-height: 2.2vw;
                    bottom: 4vw;
                }
                .overlay:after {
                    top: 15vw;
                }
            }
        }
        @include mq($until: sm){
            &__services {
                flex-direction: column;
                align-items: center;
                margin-top: 80px;
                row-gap: 10px;
                div {
                    width: 80%;
                }
                div:nth-child(2){
                    margin-top: 0;
                }
                div:nth-child(3) span{
                    bottom: 7vw;
                }
                div:nth-child(4){
                    width: 80%;
                    margin: 0;
                    a {
                        width: 100%;
                        margin: 0 auto;
                    }
                    span {
                        bottom: 8vw;
                    }
                }
                div:nth-child(5){
                    width: 80%;
                    margin: 0;
                    a {
                        width: 100%;
                        margin: 0 auto;
                    }
                    span {
                        bottom: 8vw;
                    }
                }
                span {
                    font-size: 4vw;
                    line-height: 4vw;
                    bottom: 10vw;
                }
                .overlay:after {
                    top: 40vw;
                }
            }
        }
        &__item{
            @include mq($until: xl){
                width: 16vw;
                height: 15vw;  
            }
            svg{
                display: block;
            }
            position: relative;
            width: 300px;
            height: 300px;
            .item-shape{
                object-fit: center;
            }
            @include mq($until: sm){
                min-width: 25%;
                margin: 30px 21px;    
            }
            @include mq($until: xs){
                min-width: 45%;
                margin: 30px 21px;    
            }
        }
        &__mask{
           overflow: hidden;
           width: 600px;
           height: 600px;  
        }
    }
    .sek1{
        position: relative;
        z-index: 0;
        &__fala{
            position: relative;
            z-index: -1;
        }
        &__content{
            background-color: $color-bg;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
        }
        &__head{
            max-width: 50%;
            text-align: center;
            padding-bottom: 5vw;
            @include mq($until: lg){
                max-width: 60%;   
            }
            @include mq($until: sm){
                max-width: 80%; 
                p{
                    font-size: rem(21px);
                    padding-bottom: 13px;
                }  
            }

        }
        &__headwrap{
            display: flex;
            justify-content: center;
            display: flex;
            align-items: center;
            h2{
                color: $color-heading;
                font-size: rem(45px);
                margin-bottom: 40px;
            }
            svg{
                width: 60px;
                padding-bottom: 44px;
                @include mq($until: sm){
                    padding-bottom: 13px;
                }
            }
            @include mq($until: sm){
                flex-direction: column;
                align-items: center;
            }
        }
        &__logos{
            width: 70%;  
            mix-blend-mode: multiply;
            position: relative;
            z-index: 5;
            opacity: .6;
            @include mq($until: lg){
                width: 90%;  
            }
            @include mq($until: md){
                width: 100%;  
            }
        }
        &__logo{
            max-height: 65px;
            height: auto;
            width: auto;
            transition: all .4s ease-in-out;
            &.visible {
                animation-fill-mode: unset;
            }
            a{
                display: block;
            }
            @include mq($until: lg){
               max-height: 51px;
            }
            &:hover{
                transform: translate(3px, -13px);
            }
        }
        &__banner{
            width: 65vw;
            padding-top: 5.5vw;
            padding-bottom: 5.5vw;
            height: auto;
            position: relative;
            z-index: 5 ;
            @include mq($until: lg){
                width: 80vw;
            }
            @include mq($until: md){
                width: 90vw;
            }
            @include mq($until: sm){
                width: 94vw;
            }
            @include mq($until: xs){
                width: 98vw;
            }
        }

        &__bg{
            background-image: url("/storage/app/media/main/clouds-4.png");
            background-repeat: no-repeat;
            width: 100%;
            min-height:30vh;
            height: 100%;
            background-repeat: no-repeat;
            background-size:cover;
           position: absolute;
            top: 30vw;
            mix-blend-mode: multiply;
            z-index: 1 ;
        }

        &__helpwrap{
           position: absolute;
           z-index: 5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            bottom: 19vw;
            left: 0;
            right: 0;
            @include mq($until: lg){
                bottom: 20.5vw;
            }
            @include mq($until: md){
                bottom: 21.5vw;
            }
            @include mq($until: sm){
                bottom: 23vw;
                p {display: none;}
            }
            @include mq($until: xs){
                bottom: 24vw;
            }
            @include mq($until: 400px){
                bottom: 0vw;
                font-size: 16px;
                p {display: block;}

            }

            h3{
                color: #fff;
                margin-bottom: 8px;
                @include mq($until: xs){
                    margin-bottom: 3px;
                    font-size: 16px;
                }
                @include mq($until: 400px){
                    color: $paragraph-color;
                }
    
            }
            p {
                color: #fff;
                text-decoration: underline;
                font-weight: 500;
                @include mq($until: 400px){
                    color: $paragraph-color;
                }

            }
            a{
                color: #fff;
                text-decoration: underline;
                font-weight: 500;
                transition: all .4s ease-in-out;
                &:hover{
                    color: $color-extra;
                }
            }
        }
    }

    .newsection{
        background-color: $color-bg;
        padding-top: 5vw;
        padding-bottom: 5vw;
        position: relative;
        z-index: 9;
    }
    .dzialalnosc{
        background: 
        linear-gradient(180deg, rgba(239,244,249,1) 0%, rgba(255,255,255,1) 20%,
         rgba(255,255,255,1) 79%, rgba(239,244,249,1) 100%);
        padding-top: 8vw;
        padding-bottom: 5vw;
        h2{
            color: $color-heading;
            font-size: rem(45px);
            margin-bottom: 40px;
        }

        &__wrapper{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            @include mq($until: md){
                flex-direction: column-reverse;
            }
        }
        &__content{
            max-width: 40vw;
            @include mq($until: md){
                max-width: 94%;
                text-align: center;
            }
            a{
                color: #74c4a0;
                font-size: rem(20px);
                text-decoration: underline;
                margin-top: 40px;
                display: inline-block;
                letter-spacing: 1px;
            }
        }
        &__banner{
            max-width: 40vw;
        }
    }
