.subsite {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &:after {
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        position:  absolute;
        background-image: linear-gradient(0deg, #EFF4F9 0%, transparent 100%);
        z-index: 0;
    }
    h1 {
        margin-bottom: 80px;
    }
    &__content {
        position: relative;
        z-index: 1;
        ol.links-list {
        counter-reset: orderedlist;
            li {
                list-style: none;
                position: relative;
                background: #fff;
                border-radius: 5px;
                box-shadow: 0px 4px 20px rgba(78, 69, 146, 0.07);
                transition: ease 0.3s all;
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 50px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.025' height='30.588' stroke='%23fff' viewBox='0 0 19.025 30.588'%3E%3Cg transform='translate(0.704 0.704)'%3E%3Cline x1='17.617' y1='14.59' fill='none' stroke-linecap='round' stroke-width='2'%3E%3C/line%3E%3Cline x1='17.617' y2='14.59' transform='translate(0 14.59)' fill='none' stroke-linecap='round' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 14px;
                    opacity: 0;
                    transition: ease 0.3s all;
                }
                &::before {
                    counter-increment: orderedlist;
                    content: counter(orderedlist);
                    display: block;
                    width: 30px;
                    text-align: center;
                    color: #fff;
                    background-color: $color-extra;
                    border-radius: 5px;
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    transform: translateY(-50%);
                    z-index: 1;
                    transition: ease 0.3s all;
                    line-height: 30px;
                }
                &:hover {
                    transform: translateX(-10px);
                    a {
                        color: #fff;
                    }
                    &::before {
                        background-color: #fff!important;
                    }
                    &:after {
                        opacity: 1;
                    }
                }
                &:nth-child(5n+1){
                    &:hover {
                        background-color: $color-extra;
                        &::before {
                            color: $color-extra;
                        }
                    }
                    &::before {
                        background-color: $color-extra;
                    }
                }
                &:nth-child(5n+2){
                    &:hover {
                        background-color: $color-main;
                        &::before {
                            color: $color-main;
                        }
                    }
                    &::before {
                        background-color: $color-main;
                    }
                }
                &:nth-child(5n+3){
                    &:hover {
                        background-color: $color-hover;
                        &::before {
                            color: $color-hover;
                        }
                    }
                    &::before {
                        background-color: $color-hover;
                    }
                }

                &:nth-child(5n+4){
                    &:hover {
                        background-color: #82ceab;
                        &::before {
                            color: #82ceab;
                        }
                    }
                    &::before {
                        background-color: #82ceab;
                    }
                }
                &:nth-child(5n+5){
                    &:hover {
                        background-color: #b3cfef;
                        &::before {
                            color: #b3cfef;
                        }
                    }
                    &::before {
                        background-color: #b3cfef;
                    }
                }
            }
            a {
                transition: ease 0.3s all;
                display: block;
                padding: 15px 50px 15px 60px;
                color: #000;
                position: relative;
                z-index: 1;
                &:after {
                    content: '';
                    position: relative;
                }
                &:hover {

                }
            }
        }
    }
    .news-list svg .st0 {
        fill: #fff;
    }

}
