.news-list{
    @include mq($until: xs){
        flex-direction: column;
        align-items: center;
    }
    &--promo{
        margin-bottom: 30px;
    }
    &__header-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__header{
        margin-bottom: 30px;
        color: $color-heading;
        font-size: rem(45px);
        font-weight: 500;
        &--grid{
            grid-column-start: span 4;
            margin-bottom: 0;
        }
    }
    &__link{
        color: $color-main;
    }
    &__topwrap{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
        @include mq($until: sm){
            flex-direction: column;
            gap: 20px;
        }
        h2 {
            margin-bottom: 0;
        }
        a{
            padding-left: 30px;
            text-decoration: underline;
            color: $color-subtitle;
            font-size: rem(27px);
        }
    }
}
