.logowrap{
  background-color: $color-bg;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.foot{
  background-color: $color-bg;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(180deg, transparent 0%, #fff 100%);
  }
}
.servicebar{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding-top: 3vw;
  padding-bottom: 5vw;
  @include mq($until: lg){
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
  @include mq($until: sm){
    grid-template-columns: 1fr;
  }
  &__item{
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 13px;
    box-shadow: 0px 4px 20px rgba($color-extra, 0.07);
    transition: ease 0.3s all;
    &.visible {
      animation-fill-mode: unset;
    }
    @include mq($until: lg){
      justify-content: center;
      &:nth-child(1) { grid-area: 1 / 1 / 3 / 3; }
      &:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
      &:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
      &:nth-child(4) { grid-area: 2 / 3 / 3 / 5; }
      &:nth-child(5) { grid-area: 2 / 5 / 3 / 7; }
    }
    @include mq($until: sm){
      grid-area:unset!important;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;
    }
    @include mq($until: 400px){
      flex-direction: column;
      gap: 10px;
    }
    &:hover {
      transform: translateY(-10px);
      span {
        color: #fff;
      }
      svg, path {
        fill: #fff;
      }
    }
    &:nth-child(1):hover {
      background-color: $color-extra;
    }
    &:nth-child(2):hover {
      background-color: $color-main;
    }
    &:nth-child(3):hover {
      background-color: $color-hover;
    }
    &:nth-child(4):hover {
      background-color: #82ceab;
    }
    &:nth-child(5):hover {
      background-color: #b3cfef;
    }
    svg{
      width: 7vw;
      padding-bottom: 13px;
      transition: ease 0.3s all;
      @include mq($until: sm){
        width: 70px;
        padding-bottom: 0;
      }
    }
    span{
      text-align: center;
      font-size: rem(16px);
      transition: ease 0.3s all;
    }
  }
}

.contact-data__content {
  p:first-child{
  padding-bottom: 21px;
  }
}


.mainfooter{
  position: relative;
  z-index: 1;
  padding-bottom: 4vw;
  border-bottom: 2px solid hsla(0, 0%, 0%, 0.1);
  *{
    display: block;
  }

   &__wrappers{
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     gap: 20px;
     @include mq($until: sm){
      flex-direction: column;
      justify-content: space-around;
    }
   }
    &__common{
      @include mq($until: sm){
        padding-top: 13px;
      }
    }
   &__title{
     padding-bottom: 21px;
   }
   &__donates{
     display: flex;
     flex-direction: column;
     p{
       margin-bottom: 0;
     }
   }
   .contact-data__section {
    flex-direction: column;
  }
}


.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
    font-family: 'Montserrat';
    @include mq($until: xs){
      flex-direction: column;
      gap: 10px;
    }
    a {
      color: $paragraph-color;
      &:hover {
        color: $link-color;
      }
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}