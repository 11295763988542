.header__submenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, $color-main 0%, lighten($color-main, 10%));
  align-items: flex-start;
  justify-content: flex-start;
  max-width: unset;
  top: 100%;
  padding: 15px;
  height: auto;
  max-height: 900px;
  min-width: 150px;
  opacity: 1;
  z-index: 9;
  transform: scaleY(1);
  transform-origin: top center;
  transition: opacity .6s ease-in-out, transform .3s ease-in-out;
  border-radius: 0 0 5px 5px;

  &--fullscreen {
    position: static;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    &.header__submenu--hidden{
      display: none;
    }
  }

  &--hidden {
    opacity: 0;
    transform: scaleY(0);
  }

  .header__menuItem {
    width: 100%;
    margin: 0;
    list-style: none;
    display: block;

    &--fullscreen {
      margin: 0;
    }
  }

  .header__menuLink {
    color: #fff!important;
    white-space: nowrap; 
    padding: 5px 10px;
    display: block;
    text-align: left;

    &--fullscreen {
      font-size: rem(16px);
      margin-bottom: 5px;
      text-align: center;
    }
    &:active, &--active, &--open, &:hover {
      color: $color-hover!important;
    }
  }
}

.header__menuItem:nth-child(1) .header__submenu {
  right: unset;
  left: 0;
}