.icons-set {
  display: grid;
  text-align: center;
  column-gap: 20px;
  row-gap: 30px;
  align-items: center;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-self: center;

    img {
      height: auto;
      max-height: 80px;
      width: auto;
      max-width: 130px;
    }

    &::before {
      content: none;
    }

  }
  

  &__item-text {
      font-weight: 600;
      font-size: rem(16px);
  }

  @include mq($until: md) {
    &__item {
      img {
        //width: 60px;
      }
    }
    &__item-text {
      font-size: rem(15px);
    }
  }

  @include mq($until: sm) {
    &__item-text {
      font-size: rem(13px);
    }
  }
}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq($until: lg) {
    &--5 {
      grid-template-columns: repeat(4, 1fr);
    }
  }  
  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @include mq($until: sm) {
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  }  
  @include mq($until: 320px) {
    &--5 {
      grid-template-columns: repeat(1, 1fr);
    }
  }  

}
