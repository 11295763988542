.news-card{
    display: block;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 80px;
    &:first-of-type {
        margin-top: 0;
    }
    @include mq($until: md){
        flex-direction: row;
    }
    @include mq($until: sm){
        flex-direction: column;
        text-align: center;
        width: 100%;
    }
    align-items: center;
    &__photowarp{
        position: relative;
        width: 20vw;
        @include mq($until: sm){
            width: 40vw;
        }
    }
    &__mask {
        position: relative;
        z-index: 5;
    }
    &__image{
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        z-index: 0;
        top: 0;
        left: 0;
        border: 1px solid #EFF4F9;
        @include mq($until: sm){
        }
    }
    &__header{
        font-size: rem(30px);
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 40px;
        color: $color-heading;
        @include mq($until: sm){
            text-align: center;
        }
        span {
            font-size: rem(30px);
            font-weight: 500;
            color: $color-main;
            padding-right: 20px;
            border-right: 4px solid $color-hover;
            margin-right: 20px;
            display: inline-block;
            line-height: unset;
            @include mq($until: sm){
                display: block;
                text-align: center;
                border: 0;
                margin-right: 0;
                padding: 0;
                margin-bottom: 20px;
                margin-top: 20px;
            }
        }
    }
    &__more{
        margin-top: auto;
        padding-top: 15px;
        color: $color-subtitle;
        display: inline-block;
        font-size: rem(21px);
        font-weight: 500;
        text-decoration: underline;
    }
    &__textwrap{
        margin-right: 5vw;
        max-width: 70%;
        text-align: right;
        @include mq($until: md){
            text-align: left;
            margin-right: 0;
            margin-left: 5vw;
        }
        @include mq($until: sm){
            max-width: 90%;
            margin-left: unset;
            text-align: center;
        }
    }
}
.news-card:nth-child(odd){
    flex-direction: row;
    @include mq($until: sm){
        flex-direction: column;
        text-align: center;
    }
    .news-card__textwrap {
        margin-left: 5vw;
        margin-right: 0;
        text-align: left;    
        @include mq($until: sm){
            text-align: center;
            margin-left: 0;
        }    
    }
}
