.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 200px;
  // border-bottom: solid 2px $color-main;
  // position: absolute;
  column-gap: 20px;
  z-index: 99999;
  @include mq($until: md){
    min-height: 120px;
  }
  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }
  &__menu {
    border-radius: 5px;
    background-color: #E2EDF6;
    @include mq($until: md){
      background-color: unset;
      border-radius: 0;
    }
  }
  &__logo{
    position: relative;
    z-index: 2;
    max-width: 21%;
    img {
      height: auto;
    }
  }

  &__nav {
    &--main {
      border-radius: 5px;
      padding: 0 10px;
      background-color: $color-extra;
      @include mq($until: md){
        border-radius: 0;
        background: unset;
        padding: 0;
      }
    }
    &--extra {
      border-radius: 5px 5px 0 0;
      background: linear-gradient(0deg, #E2EDF6 0%, #fff 100%);
      @include mq($until: md){
        border-radius: 0;
        background: unset;
      }
      .header__menuItem:first-of-type {
        flex-grow: 1;
      }
      .header__menuLink {
        color: $color-extra;
        &:active, &--active, &--open, &:hover {
          color: $color-main;
        }
      }
    }
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
      @include mq($until: md){
        overflow: auto;
        border-bottom: 120px solid transparent;
      }
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    padding: 0;
    margin: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
    &.toright .header__submenu {
      right: 0;
    }
    &.tocenter .header__submenu {
      left: 50%;
      transform: translateX(-50%) scale(1);
      @include mq($until: md){
        left: unset;
        transform: scale(1);
      }
      &.header__submenu--hidden {
        transform: translateX(-50%) scale(0);
      }
    }
  }

  &__menuLink {
    color: #ffffff;
    padding: 10px 15px;
    display: inline-block;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-hover;
    }
  }
}